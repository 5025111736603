@charset "UTF-8";

@import "../mixin";
@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,500,600,700,900);
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);

.gl_header {
  background-color: $colorMain;
  color: #fff;
  &--in {
    max-width: 1030px;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &--logo {
  }
  &--link {
    display: inline-block;
    text-decoration: none;
    font-size: 25px;
    font-family: 'Raleway', sans-serif;
    color: #fff;
  }
  &--catch {
    font-size: 10px;
    letter-spacing: 1px;
    line-height: 1.3;
    margin: 0 0 0 20px;
  }
}

.main {
  display: block;
  overflow: hidden;
  position: relative;
  color: #fff;
  &:before {
    content: "";
    background: url('/images/index-bg4.jpg');
    background-size: cover;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    transform: scale(1.1, 1.1);
    animation: opening 2s forwards;
  }
  @keyframes opening {
    0% {
      transform: scale(1.1, 1.1);
    }
    10% {
      transform: scale(1.1, 1.1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  &--in {
    max-width: 1030px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    min-height: calc(100vh - 56px);
    padding: 50px 0;
    box-sizing: border-box;
  }
  &--content {
    max-width: 575px;
    position: relative;
  }
  &--ttl {
    font-size: 40px;
    font-weight: 600;
    margin: 0 0 50px 0;
    font-family: 'Raleway', sans-serif;
  }
  &--ttl_description {
    font-size: 16px;
  }
  &--description {
    font-size: 20px;
    line-height: 2;
    letter-spacing: 0.5px;
    margin: 0 0 30px 0;
  }
}

.form_box {
  &--input {
    display: block;
    appearance: none;
    width: 100%;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    color: #333;
    padding: 0 10px;
    border: 0;
    border-radius: 3px;
    box-sizing: border-box;
    margin: 0 0 30px 0;
    background-color: rgba(255, 255, 255, 0.8);
  }
  &--radio_box {
    display: block;
    margin: 0 0 30px 0;
  }
  &--radio_label {
    cursor: pointer;
    &:last-child {
      margin-left: 20px;
    }
  }
  &--radio_btn {
    appearance: none;
    position: relative;
    padding: 0 0 0 20px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      border: 2px solid #fff;
      border-radius: 50%;
      left: 0;
      top: -12px;
      width: 14px;
      height: 14px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 4px;
      top: -8px;
      width: 10px;
      height: 10px;
      background-color: $colorMain;
      border-radius: 50%;
      transition: 0.15s;
      transform: scale(0, 0);
    }
    &:checked {
      &:after {
        transform: scale(1, 1);
      }
    }
  }
  &--submit {
    border-radius: 5px;
    background-color: $colorMain;
    color: #fff;
    width: 300px;
    height: 55px;
    line-height: 55px;
    font-size: 18px;
    text-align: center;
    appearance: none;
    border: 0px;
    padding: 0;
    box-shadow: 0 5px 0 darken($colorMain, 10%);
    cursor: pointer;
    position: relative;
    top: 0;
    transition: 0.15s;
    font-weight: 800;
    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-color: green;
    }
    &:hover {
      box-shadow: 0 0 0 darken($colorMain, 10%);
      top: 5px;
    }
  }
  &--attention {
    color: #fff;
    display: block;
    margin: 0 0 80px 0;
    &:before {
      content: '>';
      display: inline-block;
      transform: scale(0.4, 1.1);
      position: relative;
      top: -1px;
      margin: 0 5px 0 0;
    }
  }
}

.gl_nav {
  margin: 0 0 0 80px;
  height: 56px;
  &--link {
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    font-weight: bold;
    position: relative;
    display: block;
    transition: 0.15s;
    padding: 15px 0;
    &:before {
      content: "";
      display: block;
      border-style: solid;
      border-width: 6px 5px 0 5px;
      border-color: #fff transparent transparent transparent;
      bottom: 1px;
      position: absolute;
      left: calc(50% - 3px);
      transition: 0.15s;
    }
    &:hover {
      color: #000;
      &:before {
        bottom: -2px;
        border-color: #000 transparent transparent transparent;
      }
    }
  }
}

.howto {
  background-color: rgba(255,255,255,0.5);
  border-radius: 5px;
  padding: 50px 30px;
  box-sizing: border-box;
  max-width: 100%;
  color: #333;
  &--content {
  }
  &--ttl {
    font-size: 40px;
    margin: 0 0 20px 0;
  }
  &--description {
    font-size: 20px;
    line-height: 2;
    letter-spacing: 0.5px;
    margin: 0 0 60px 0;
  }
}

.howto_box {
  margin: 0 0 80px 0;
  &:last-child {
    margin-bottom: 0;
  }
  &--ttl {
    font-size: 25px;
    margin: 0 0 20px 0;
  }
  &--img {
  }
  &--description {
    font-size: 16px;
    line-height: 2;
    letter-spacing: 0.5px;
    margin: 0 0 30px 0;
  }
  &--link {
  }
}
